// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chi-siamo-js": () => import("./../../../src/pages/chi-siamo.js" /* webpackChunkName: "component---src-pages-chi-siamo-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-recent-posts-js": () => import("./../../../src/pages/recent-posts.js" /* webpackChunkName: "component---src-pages-recent-posts-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/single-post.js" /* webpackChunkName: "component---src-templates-single-post-js" */)
}

